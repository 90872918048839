@import 'src/styles/imports';

.component {
  position: absolute;
  z-index: $z-index-dropdown;
  top: 130%;
  right: 0;
  box-shadow: $box-shadow;
  border-radius: var(--themes-buttons-border-radius);
  background: $color-white;

  .menu-items {
    min-width: 11.5em;

    .nested-header-content {
      display: flex;
      width: 100%;
      cursor: pointer;
      align-items: center;
    }

    .nested-header { 
      font-size: .9375em;
      position: relative;
      z-index: 2;
      display: block;
      width: 100%;
      height: auto;
      padding: .7em 1em;
      margin: 0;
      color: $color-grey-10;
      font-weight: 300;
      cursor: pointer;
      white-space: nowrap;
      justify-content: initial;
    }

    .nested-header-content:hover .nested-header::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0.3em;
      background-color: var(--themes-colors-primary);
    }

    .nested-item-header.active .nested-arrow {
      transform: rotate(180deg);
      transform-origin: 55% 45%;
      transition: all .2s ease-in-out;
    }

    .nested-arrow {
      position: relative;
      z-index: 1;
      margin-right: 15px;
      transition: all .2s ease-in-out;
    }

    li {
      a {
        font-size: .9375em;
        width: 100%;
        height: auto;
        padding: .7em 1em;
        margin: 0;
        color: $color-grey-10;
        font-weight: 300;
        white-space: nowrap;
        justify-content: initial;

        &:hover {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: .3em;
            background-color: var(--themes-colors-primary);
          }
        }
      }
    }

    .nested-list {
      position: relative;
      border-left: 1px solid $color-dark-navy-20;
      margin-left: 1em;
    }
  }

  @include desktop {
    .mobile {
      display: none;
    }
  }

  &.exp {
    top: 170%;
    padding: 1em 0;
    border-radius: 8px;

    .menu-items {
      li {
        a {
          font-size: 1em;
          display: block;
          font-weight: $font-weight-regular;
          text-align: left;
          transition: all .3s ease;

          &:hover {
            background-color: $color-dark-navy-bg;
            color: $color-modern-silver-gradient;
            font-weight: $font-weight-bold;

            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
}
